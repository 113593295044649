@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  :root {
    --cursor-color: black;
    --cursor-dot-size: 5px;
    --cursor-dot-size-hover: 15px;
  }

/* Add these subtle styles to your global CSS */

/* Improve text readability with proper line height */
body {
  line-height: 0.5;
  letter-spacing: 0.01em;
}

/* Subtle link transitions */
a {
  transition: color 0.3s ease;
}

/* Add very subtle border to active navigation */
.nav-active {
  border-bottom: 1px solid #b8a38b;
}

/* Gentle fade-in for page content */
@keyframes gentleFade {
  from { opacity: 0; }
  to { opacity: 1; }
}

.content-fade {
  animation: gentleFade 0.5s ease-out;
}

/* Subtle link underline effect */
.link-underline {
  position: relative;
}

.link-underline:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: -1px;
  left: 0;
  background-color: currentColor;
  transform-origin: center;
  transition: transform 0.3s ease;
}

.link-underline:hover:after {
  transform: scaleX(1);
}

  .cursor-dot,
  .cursor-outline {
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 1;
    pointer-events: none;
  }

  @media (max-width: 992px) {
    .cursor-dot, .cursor-outline {
      display: none;
    }
  }

  @media (max-height: 700px) {
    .button-hide {
      display: none;
    }
    .project-images {
      height: 40px !important;
      width: 40px !important;
    }
  }
}
